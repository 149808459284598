import * as React from "react"
import "../App.scss";
import Section from "../components/Section/Section";
import Row from "../components/Row/Row";
import Col from "../components/Col/Col";
import CtaBar from "../components/CtaBar/CtaBar";
import Review from "../components/Review/Review";
import Reviews from "../components/Reviews/Reviews";
import Nav from "../components/Nav/Nav";
import Footer from "../components/Footer/Footer";
import Button from "../components/Button/Button";
import video from "../img/video.mp4"
import imageQuestion1 from "../img/q1.jpg"
import blurText from "../img/blurText.png"
import circle from "../img/circle.svg"

import review1 from "../img/review1.jpg"
import review2 from "../img/review2.jpg"
import review3 from "../img/review3.jpg"
import review4 from "../img/review4.jpg"
import review5 from "../img/review5.jpg"
import review6 from "../img/review6.jpg"
import review7 from "../img/review7.jpg"
import review8 from "../img/review8.jpg"
import review9 from "../img/review9.jpg"
import review10 from "../img/review10.jpg"
import review11 from "../img/review11.jpg"
import review12 from "../img/review12.jpg"

import playIcon from "../img/playIcon.svg"
import heroImage from "../img/hero_team.jpg"
import mindtrepreneurStrip from "../img/mindtrepreneur_strip.jpg"


// data

{/*

const pos = { x : 0, y : 0 };

const saveCursorPosition = function(x, y) {
    pos.x = (x / window.innerWidth).toFixed(2);
    pos.y = (y / window.innerHeight).toFixed(2);
    document.documentElement.style.setProperty('--x', pos.x);
    document.documentElement.style.setProperty('--y', pos.y);
}

document.addEventListener('mousemove', e => { saveCursorPosition(e.clientX, e.clientY); })

*/}

{/*

const one = document.getElementById('hero');

window.addEventListener('scroll', () => {
  let scrollY = window.scrollY;
  document.documentElement.style.setProperty('--scrollPosition', scrollY);
})

*/}

// markup
const Testimonials = () => {
  return (
    <main>
      <Nav />
      <Section className="hero heroLow" id="hero">
        <Row>

          <Col size="50">
            <h1>Success stories</h1>
          </Col>
          <Col size="50" className="hero__button">
            <Button type="secondary" text="Join FREE Training" link="https://go.mindtrepreneur.com/optin-page" />
          </Col>
        </Row>
      </Section>

      <Section className="reviews reviewsAll">

          <Review
            id="review__1"
            link="https://vimeo.com/user107734185/review/609028016/47f22abc75"
            image={review1}
            className="active"
            name="Enver Mehmet"
            text="I had all the courses from all the gurus, I knew what to do but I wasn't doing it. Since joining Mindtrepreneur I totally transformed my life, transformed my business - hitting $10,000 a month - and ultimately my identity. I feel more abundant & my relationships are now more fulfilling. This program will serve me for the rest of my life."
          />
          <Review
            id="review__2"
            link="https://vimeo.com/user107734185/review/707764108/66d468c5ea"
            image={review2}
            className="active"
            name="Craig Stewart"
            text="Jon & Vash always help me discover the root cause of problems and help me overcome them. This allowed me to scale up the business, improve my relationships, be more confident, and much more. I am super grateful and my life is completely different."
          />
          <Review
            id="review__3"
            link="https://vimeo.com/user107734185/review/710762777/751aba771b"
            image={review3}
            className="active"
            name="Tyrelle Anderson-Brown"
            text="Before starting with Jon, it was tough, I wasn't making much money, I didn't have any routines or systems. Once I locked in with Jon, it changed my life. Since then, I've been able to travel the world, I own $20 million crypto fond. Mindset is really the key to everything."
          />
          <Review
            id="review__4"
            link="https://vimeo.com/user107734185/review/650549212/8b1c4c2f29"
            image={review4}
            className="active"
            name="Mac Magyaros"
            text="Hands down the most valuable program and the best decision I’ve made as an entrepreneur. It totally transformed my life - I am happier in my life, I have a clear vision and purpose now, and I have a consistent way to sign clients! I can’t say enough good things about Mindtrepreneur."
          />
          <Review
            id="review__5"
            link="https://vimeo.com/user107734185/review/636168183/ce5eb990bc"
            image={review5}
            className="active"
            name="Deyan Babic"
            text="Jon & Vash helped me so much to achieve my goal of living life of my dreams. Before joining Mindtrepreneur, I was working so hard, I was absolutely not happy almost depressed, and I got less results. Now I follow the advice from Jon and Vash, I work less hours and get more results."
          />
          <Review
            id="review__6"
            link="https://vimeo.com/user107734185/review/710824344/53659d6006"
            image={review6}
            className="active"
            name="Xabiso Mdluli"
            text="Because of Mindtrepreneur, I became not only a better entrepreneur, but also a better person - somebody I am proud of, in love with. Having Jon & Vash as my mentors is honestly the best thing that happened to me. I am so grateful to have them as my mentors, they honestly changed my life."
          />
          <Review
            id="review__7"
            link="https://vimeo.com/user107734185/review/609022225/ba9ddb7820"
            image={review7}
            className="active"
            name="Eric Rozhko"
            text="The Mindtrepreneur program is absolutely insane, it's been life-changing for me. Before working with Jon & Vash, my agency was struggling at $2,000, within 2 months I hit $12,000 and this month I am about to make $30,000 a month. These guys literally overdeliver and they are super transparent."
          />
          <Review
            id="review__8"
            link="https://vimeo.com/user107734185/review/646809820/5ac859d513"
            image={review8}
            className="active"
            name="Juliet C. Lee"
            text="I've invested in many courses and, honestly, this is the only program where I actually got results. Jon & Vash always overdeliver, you can really tell that they actually care about the people in the program. They cover everything to have successful and happy life. This is literally the best thing that happened to me."
          />
          <Review
            id="review__9"
            link="https://vimeo.com/user107734185/review/669841694/684a83b55b"
            image={review9}
            className="active"
            name="Nicholas William Young"
            text="I actually hated the course when I first got into it. I was grinding so much and seeing no results before joining. But the course is not only going to change your business, it's going to change your life. After joining Mind Systems I finally hit the $10k a month mark."
          />
          <Review
            id="review__10"
            link="https://vimeo.com/user107734185/review/617150514/a00ce11350"
            image={review10}
            className="active"
            name="Matthew James"
            text="After working with Jon & Vash my business has completely transformed from where it was, my mindset and the way I think in general transformed. They are always overdelivering on the calls."
          />
          <Review
            id="review__11"
            link="https://vimeo.com/user107734185/review/616891033/5be93ee9d5"
            image={review11}
            className="active"
            name="Erik Erdei"
            text="I had all the courses under the sun, I signed a few clients but always lost them. Until finally Mindtrepreneur has totally changed the game! Super thankful for what you, guys, are doing. I managed to sign my biggest client! You guys always overdeliver, you should 10x the price!"
          />
          <Review
            id="review__12"
            link="https://vimeo.com/user107734185/review/609025737/d92a006b3f"
            image={review12}
            className="active"
            name="Benjamin Hyppönen"
            text="I felt lost, making $2,000 a month with my agency. Now, I am hitting $10,000 a month consistently. It's been definitely the best investment I have ever done."
          />

      </Section>


      <Footer/>

    </main>
  )
}

export default Testimonials
