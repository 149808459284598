import React, { ReactChild, ReactChildren } from "react";
import Col, { ColProps } from "../Col/Col";
import "./Review.scss"
import Button from '../Button/Button';
import playIcon from "../../img/playIconBig.svg"

interface ReviewsProps {
  className?: string
  id?: string
  name?: string
  text?: string
  image?: string
  link?: string
}

const Reviews = ({
  className,
  id,
  name,
  text,
  image,
  link
} : ReviewsProps) => {

  return (
      <div className={`${className} review`} id={id}>
        <Col size="50" className="review__images">
          <a href={link} className="review__play">
            <img src={playIcon} alt="review" />
            <div>
              <h4>Play</h4>
              <p>full {name} review on vimeo</p>
            </div>
          </a>
          <img className="review__image" src={image} alt="review" />
        </Col>
        <Col size="50" className="review__text">
          <h4>{name}</h4>
          <p>{text}</p>
          {/*<Button type="secondary" link={link} text="Watch review on Vimeo" />*/}
        </Col>
      </div>
  )
};

export default Reviews;
